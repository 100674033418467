import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  // {
  //   id: 1,
  //   icon: "images/ui.png",
  //   title: "UI/UX design",
  //   content:
  //     "Designing quality user interfaces and integrating rich user experience flows throughout the app",
  //   color: "#F9D74C",
  //   contentColor: "dark",
  // },
  {
    id: 1,
    icon: "images/front-end.png",
    title: "Front-End Development",
    content:
      "Developing highly scalable and reusable components that make up a web application, and integrating with backend services",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/maintenance.png",
    title: "Back-End Development",
    content:
      "Developing highly scalable and stateless layers that communicate effectively with external data stores and web applications",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/idea.png",
    title: "Solution Design",
    content:
      "Designing and architecting innovative solutions to complex problems using best-practise patterns and latest technologies.",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Interested in working together?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
