import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const experienceRight = [
  {
    id: 0,
    companyLogo: "images/westpac.png",
    title: "Information Security Analyst",
    years: "2019",
    content:
      "",
  },
  {
    id: 1,
    companyLogo: "images/deloitte.jpeg",
    title: "Platform Engineer",
    years: "2018",
    content:
      "",
  },
  {
    id: 2,
    companyLogo: "images/asic.png",
    title: "Business Intelligence Analyst",
    years: "2018",
    content:
      "",
  },
  {
    id: 3,
    companyLogo: "images/vivant.jpg",
    title: "UI Developer",
    years: "2017",
    content:
      "",
  },
  {
    id: 4,
    companyLogo: "images/uts.png",
    title: "Bachelor of Information Technology",
    years: "‎",
    content:
      "",
  },
];

const experienceLeft = [
  {
    id: 0,
    companyLogo: "images/qbe.png",
    title: "Technical Lead",
    years: "2022 - Present",
    content:
      "",
  },
  {
    id: 1,
    companyLogo: "images/qbe.png",
    title: "Lead Software Engineer",
    years: "2021",
    content:
      "",
  },
  {
    id: 2,
    companyLogo: "images/westpac.png",
    title: "Senior Software Enginer",
    years: "2020",
    content:
      "",
  },
  {
    id: 3,
    companyLogo: "images/westpac.png",
    title: "Software Enginer",
    years: "2019",
    content:
      "",
  },
  {
    id: 4,
    companyLogo: "images/kuebik.png",
    title: "Co-Founder",
    years: "2019 - Present",
    content:
      "",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceLeft.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceRight.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
