import React from "react";
import Pagetitle from "../elements/Pagetitle";

const skillsData = [
  "React",
  "React-Native",
  "Angular",
  "Typescript",
  "Javascript",
  "RxJS",
  "Redux",
  "GIT",
  "Java",
  "C#",
  "SQL",
  "PL/SQL",
  "Python",
  "Node",
  "Ionic",
  "AWS",
  "GCP",
  "Docker",
  "MongoDB",
  "Firebase",
  "Chai",
  "Mocha",
  "Jest",
  "Enzyme",
  "TDD",
];

const colours = [
  { bg: "#FDD05C", text: "black" },
  { bg: "#FF4C60", text: "white" },
  { bg: "#44D7B6", text: "black" },
  { bg: "#6C6CE5", text: "white" },
];

function Skill({ skill }) {
  const { bg, text } = colours[Math.floor(Math.random() * colours.length)];
  return (
    <button
      type="button"
      style={{
        backgroundColor: bg,
        color: text,
        cursor: "default",
      }}
      className="btn btn-md m-2"
    >
      {skill}
    </button>
  );
}

function Skills() {
  return (
    <section id="skills">
      <div className="container">
        <Pagetitle title="Skills" />
        <div className="d-flex flex-wrap justify-content-center">
          {skillsData.map((skill, index) => (
            <Skill key={index} skill={skill} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
