import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function Timeline({ education }) {
  const { years, title, content, companyLogo } = education;
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="timeline-container">
        <div className="content">
          <span className="time">{years}</span>
          <div className="d-flex">
            <img
              width={30}
              height={30}
              src={companyLogo}
              alt={companyLogo}
              className="align-self-center"
            />
            <h3 className="title m-2">{title}</h3>
          </div>
          <p>{content}</p>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Timeline;
