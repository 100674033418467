import React, { useState } from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Rishy",
  avatarImage: "/images/avatar-rishy.png",
  content: `Hi there! I am Rishy - a seasoned technologist based in Sydney. With 7+ years of industry experience working for large enterprises in the fintech space, I have a wealth of knowledge when it comes to building modern, scalable and performant software across the full stack. I am passionate about learning, solving complex problems, and mentoring the next generation of engineers.`
};

const progressData = [
  {
    id: 1,
    title: "Front-End Development",
    percantage: 100,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Back-End Development",
    percantage: 80,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Solution Design",
    percantage: 90,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects shipped to production",
    count: 10,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Satisfied clients",
    count: 20,
    icon: "icon-people",
  },
  {
    id: 3,
    title: "Years of professional experience",
    count: 7,
    icon: "icon-badge",
  },
  {
    id: 4,
    title: "Cups of coffee",
    count: 5670,
    icon: "icon-cup",
  },
];

const currentYear = new Date().getFullYear()  // returns the current year

function About() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordPrompt, setPasswordPrompt] = useState(false);

  const handleDownload = (e) => {
    if (password !== `resume${currentYear}`) {
      e.preventDefault();
      setError("Invalid Password");
    } else {
      setError("");
      setPassword("");
      setPasswordPrompt(false);
    }
  };

  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img
                width={150}
                height={150}
                className="rounded-circle"
                src={aboutContent.avatarImage}
                alt={aboutContent.name}
              />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  {passwordPrompt ? (
                    <div
                      style={{
                        animation: "fadeIn 500ms",
                      }}
                      className="w-100"
                    >
                      {error && (
                        <label htmlFor="password" className="text-danger">
                          {error}
                        </label>
                      )}
                      <div className="input-group flex-nowrap">
                        <div className="input-group-prepend">
                          <button
                            style={{
                              borderRadius: "5px 0 0 5px",
                              fontSize: 16,
                              width: "50px",
                            }}
                            className="btn btn-sm btn-danger h-100"
                            type="button"
                            onClick={() => {
                              setPassword("");
                              setError("");
                              setPasswordPrompt(false);
                            }}
                          >
                            <i className="fa fa-times" />
                          </button>
                        </div>
                        <input
                          id="password"
                          type="password"
                          style={{ flexGrow: 1 }}
                          className="form-control-default"
                          placeholder="Password"
                          aria-label="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <a
                            href="files/rishy-resume.pdf"
                            style={{
                              borderRadius: "0 5px 5px 0",
                              fontSize: 16,
                              width: "50px",
                            }}
                            className="btn btn-sm btn-success h-100"
                            type="button"
                            onClick={handleDownload}
                            download
                          >
                            <i className="fa fa-download" />
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <button
                      style={{ animation: "fadeIn 500ms" }}
                      className="btn btn-default"
                      onClick={() => setPasswordPrompt(true)}
                    >
                      Download CV
                    </button>
                  )}
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
